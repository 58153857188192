.App {
    text-align: center;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
}

body {
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block {
    position: relative;
    color: white;
    padding: 2vw 5vw;
    font-size: 8em;
    font-weight: 700;
    background: linear-gradient(0deg, #000, #262626);
    border-radius: 20px;
    line-height: 1.05;
    letter-spacing: -0.015em;
}

@media (min-device-width: 800px) {
    .block {
        /* max-width: 40vw; */
    }
}

@media only screen and (max-width: 600px) {
    .block {
        font-size: 5em ;
        max-width: 70%;
        padding: 15px;
    }
}

.shadow:before, .shadow:after {
    -webkit-border-radius: 20px;
    border-radius: 20px;
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
    background-size: 400%;
    z-index: -1;
    animation: shadow 20s linear infinite;
}

.shadow:after {
    -webkit-border-radius: 20px;
    border-radius: 20px;
    top: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    filter: blur(24px);
    opacity: 0.9;
    /* -webkit-mask-image: radial-gradient(white, black); */
    /* mask-image: radial-gradient(white, black); */
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    /* -webkit-mask-image: -webkit-radial-gradient(circle, white, white); */
}

@keyframes shadow {
    0% {
        background-position: 0 0;
        -webkit-border-radius: 20px;
        border-radius: 20px;
    }
    50.01% {
        background-position: 200% 0;
        -webkit-border-radius: 20px;
        border-radius: 20px;
    }
    100% {
        background-position: 0 0;
        -webkit-border-radius: 20px;
        border-radius: 20px;
    }
}

.subtitle {
    color: white;
    margin-top: 50px;
    font-size: 40px;
    font-weight: 500;
    padding: 10;
}
.highlight {
    background-image: linear-gradient(45deg, #D676EB, #8227FF);
    -webkit-background-clip: text;
    color: transparent;
    display: inline;
}

.mail-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

.mail-input {
    font-size: 16px;
    border-radius: 6px;
    padding: 10px 15px;
    border: none;
    background-color: #ffffff;
    outline: none;
    width: 300px;
}

.mail-btn {
    font-size: 16px;
    border-radius: 6px;
    padding: 10px 25px;
    border: none;
    background-color: #ffffff;
    color: #000;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    text-decoration: none;
    margin: 0 10px;
}

.mail-btn:hover {
    background-color: #575555;
    color: white;
}

.mail-success {
    color: white;
}

.link {
    color: #51A0D5;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
    font-size: 1.5em;
}